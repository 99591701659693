@use '@/styles/global' as g;

$SiriusXM-Light-Grey-Background: #F7F7F7;
$SiriusXM-Web-Text-Grey: #646464;
$SiriusXM-Aldebaran-Orange: rgba(255, 97, 41, 1);
$SiriusXM-Rigel-Cyan: rgba(49, 200, 255, 1);
$SiriusXM-Antares-Red: #E9221A;

.modalImage {
  width: 40%;
  align-self: flex-end;

  img {
      padding: 0 40px;

      @include g.bp-max(lg) {
          padding: 0 12px;
      }

      @include g.bp(lg) {
          width: 100%;
      }
  }

  .colorBlock {
      position: absolute;
      width: 40%;
      top: 25%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: -1;

      .red {
          height: 60px;
          width: 100px;
          background-color: $SiriusXM-Antares-Red;
      }
  
      .orange {
          height: 90px;
          width: 175px;
          background-color: $SiriusXM-Aldebaran-Orange;
          align-self: end;
      }
  
      .blue {
          height: 120px;
          width: 100px;
          background-color: $SiriusXM-Rigel-Cyan;

          @include g.bp(md) {
              height: 110px;
          }

          @include g.bp(lg) {
              height: 120px;
          }
      }
  }

  @include g.bp-max(md) {
      display: none;
  }
}