@use '@/styles/global' as g;

.channelCard {
    position: relative;
    display: flex;
    width: 100%;
    height: 104px;
    min-width: 80px;
    cursor: pointer;
    background: g.$white;

    @include g.web2PageOverrides {
        border-radius: 24px;
    }

    @include g.bp(sm) {
        height: 120px;
    }

    @include g.bp(lg) {
        height: 352px;
        max-width: 288px;
        min-width: 80px;
        padding: 0;
        z-index: 1;

        :hover>& {
            z-index: 2;
        }
    }

    .contentWrapper {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        min-width: 100%;
        height: 100%;
        z-index: 3;
        position: absolute;
        right: 0;
        padding-left: 20px;
        box-shadow: g.$active-shadow;

        @include g.web2PageOverrides {
            border-radius: 24px;
        }

        @include g.bp(sm) {
            padding-left: 8px;
        }

        @include g.bp(lg) {
            position: unset;
            flex-direction: column;
            align-items: center;
            justify-content: unset;
            padding-left: 0;
            box-shadow: unset;
        }
    }

    .radioInfoContainer {
        display: flex;
        min-width: 0;
        align-items: center;

        @include g.bp(sm) {
            width: 366px;
        }

        @include g.bp(lg) {
            flex-direction: column;
        }
    }

    .radioImageLink {
        margin-right: 30px;
        margin-left: 10px;
        display: none;
        justify-content: center;
        position: relative;
        width: 104px;

        @include g.bp(sm) {
            display: flex;
        }

        @include g.bp(lg) {
            margin: 20px 0 8px 0;
            height: 50px;
        }

        .radioImageBackground {
            position: absolute;
            top: 8px;
            left: -11px;
            height: 104px;
            width: 104px;
            background: g.$off-white;

            @include g.bp(lg) {
                display: none;
            }
        }
    }

    .radioImage {
        width: 80px;
        height: 120px;
        z-index: 1;

        @include g.bp(lg) {
            max-height: 50px;
            max-width: 104px;
            height: unset;
            width: unset;
        }
    }

    .radioTextInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: g.$black;
        width: 154px;

        >p,
        >a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include g.bp(sm) {
                overflow: visible;
            }

            @include g.bp(lg) {
                white-space: wrap;
                text-align: center;
            }
        }

        @include g.bp(sm) {
            width: 100%;
        }

        @include g.bp(lg) {
            align-items: center;
            width: 245px;
        }

        p:first-of-type {
            @include g.font-size(14px);
            @include g.set-font-weight('bold');
            margin: 0;
        }

        p:last-of-type {
            margin: 2px 0 8px;
        }

        p,
        a {
            @include g.font-size(12px);
        }

        a {
            color: g.$blue-secondary;
            text-transform: capitalize;
            @include g.web2PageOverrides {
                color: var(--base-contrast-accent, #0000eb);
            }
        }
    }

    .albumContainerMobile {
        display: flex;
        justify-content: flex-end;
        border: 2px;
        border-color: transparent;
        background-color: transparent;
        padding: 0;

        @include g.bp(lg) {
            display: none;
        }
    }

    .albumContainerMobile,
    .albumContainerDesktop {
        text-decoration: none;

        .button {
            display: flex;
            background: none;
            border: none;
            height: 100%;
            align-items: center;
            padding-left: 0;
            transition: padding 0.3s ease-in-out;

            @include g.bp(sm) {
                padding-left: 16px;
            }

            @include g.bp(lg) {
                padding-left: 0;
                height: unset;
                display: block;
            }

            .kabab {
                width: 24px;
                margin-right: 8px;

                @include g.bp(lg) {
                    display: none;
                }
            }

            .albumWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 88px;
                height: 88px;
                overflow: hidden;
                background-color: g.$off-white;
                margin: 8px;
                transition: margin 0.3s ease-in-out;

                @media only screen and (min-width: 375px) {
                    width: 104px;
                    height: 88px;
                    margin: 0 8px 0 0;
                }

                @include g.bp(sm) {
                    height: 104px;
                }

                @include g.bp(lg) {
                    width: 136px;
                    height: 136px;

                    .albumCover {
                        :hover>& {
                            background-color: rgba(0, 0, 0, 0.8);

                            p,
                            span {
                                opacity: 1;
                            }
                        }
                    }
                }

                .album {
                    padding: 0 22px;

                    @include g.bp(lg) {
                        padding: 0 30px;
                    }
                }
            }
        }

        .colorBorder {
            width: 4px;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition-timing-function: ease-in-out;
            transition: width 0.3s;

            @include g.bp(lg) {
                width: 100%;
            }

            .borderBackground {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition-timing-function: ease-in-out;
                transition:
                    opacity 0.3s,
                    width 0.3s,
                    height 0.3s;

                @include g.bp(lg) {
                    display: none;
                }
            }

            .listenLiveText {
                display: flex;
                opacity: 0;
                position: relative;
                height: 40px;
                width: 72px;
                @include g.font-size(12px);
                margin-left: 32px;
                @include g.set-font-weight('bold');
                color: g.$blue;
                letter-spacing: 1.7px;
                line-height: 20px;
                justify-content: center;
                letter-spacing: 1.7px;
                line-height: 20px;
                text-transform: uppercase;
                overflow-wrap: break-word;
                text-align: center;
                transition: opacity 0.3s ease-in-out;

                @include g.bp(lg) {
                    opacity: 0;
                    height: 100%;
                    width: 100%;
                    overflow-wrap: unset;
                    transition: opacity 0.2s ease-out;
                    margin-left: 0;
                    padding-top: 28px;

                    :hover>& {
                        opacity: 1;
                        transition: 0.3s ease-in;
                        transition-delay: 0.1s;
                    }
                }
            }

            .playIcon {
                // icon is not centered in paint bounds
                // so numbers are compensating for that
                // to center and size icon to 48px
                position: absolute;
                top: calc(50% - 27px);
                left: calc(27px - 50%);
                width: 66px;
                opacity: 0;
                transition-timing-function: ease-in-out;
                transition:
                    top 0.3s,
                    left 0.3s,
                    opacity 0.3s;

                @include g.bp(lg) {
                    bottom: 0px;
                    left: calc(50% - 33px);
                    top: 65px;
                    opacity: 0;

                    :hover>& {
                        top: -28px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .albumContainerDesktop {
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100%;
        display: none;
        margin-top: 20px;

        @include g.bp(lg) {
            display: flex;
        }
    }

    .shadow {
        display: none;
    }

    @include g.bp(lg) {
        .shadow {
            display: block;
            box-shadow: g.$active-shadow;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: white;
            position: absolute;
            z-index: 2;
            transition: all 0.3s ease-in-out;
            
            @include g.web2PageOverrides {
                border-radius: 24px;
                overflow: hidden;
            }

            :hover>& {
                box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.25);
                width: calc(100% + 16px);
                height: calc(100% + 16px);
            }

            .desktopColorBorder {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 4px;
                opacity: 1;
                transition: all 0.3s ease-in-out;

                :hover>& {
                    height: 61px;
                    opacity: 0.15;
                }
            }
        }
    }
}

.active {
    .albumContainerMobile {
        .albumWrapper {
            margin-right: 0;
        }

        button {
            padding-right: 0;
        }

        .colorBorder {
            width: 120px;

            @include g.bp(lg) {
                display: block;
                height: 48px;
                margin-top: 0;
            }

            .borderBackground {
                opacity: 0.15;
            }

            .listenLiveText {
                display: flex;
                opacity: 1;
            }

            .playIcon {
                opacity: 1;
            }
        }
    }
}