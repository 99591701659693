@use '@/styles/global' as g;

$SiriusXM-Light-Grey-Background: #F7F7F7;
$SiriusXM-Web-Text-Grey: #646464;

.fixedForm {
    display: flex;
    flex-direction: column;
}

.modalWrapper { 
    dialog {
        border-radius: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        @include g.bp-max(lg) {
            max-width: 700px !important;
            height: max-content !important;
        }

        @include g.bp-max(md) {
            max-width: 95% !important;
            height: max-content !important;
        }
    }

    h2 {
        color: g.$white;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
        overflow-wrap: break-word;
    }
}

.modalContainer {
    align-items: center;
    @include g.font-size(16px);
    width: 100%;

    & > div {
        display: flex;
    }

    a {
        @include g.set-font-weight('bold');
        color: g.$medium-black;
    }
    
    label {    
        @include g.font-size(12px);
    }
    
    input {
        @include g.font-size(14px);
        width: 100%;
        line-height: 1.5rem;
        padding: 1rem 1.5rem;
        border-radius: 8px;
        background: #fff;
        color: g.$silver;
        margin: 0;
        border: 1px solid #E6E6E6;
        color: g.$black;
        box-shadow: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        align-self: flex-start;
    }
    
    button {
        cursor: pointer;
        @include g.font-size(14px);
        @include g.set-font-weight('medium');
        line-height: 1.5rem;
        padding: 12px 24px;
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
        width: 100%;       
        white-space: normal;
        border: none;
        border-radius: 40px;
        background: g.$blue;
        color: g.$white;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        justify-content: center;

        @include g.bp-max(md) {
            padding: 1rem 1.5rem;
        }

        @include g.bp(lg) {
            padding: 1rem 1.5rem;
        }   
    }

    input:focus {
        outline: 0;
    }
}

.modalDialog {
    background-color: $SiriusXM-Light-Grey-Background;
    padding: 6px;
    gap: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;

    @include g.bp-max(md) {
        padding: 0px;
        width: 100%;
        align-self: stretch;
        text-align: center;
    }

    @include g.bp(md) {         
        width: 65%;
        gap: 5px;
        padding: 12px;
    }

    @include g.bp(lg) {
        gap: 5px;
        padding: 16px 32px 24px;
    }   
}

.moduleHeader{
    @include g.font-size(40px);
    @include g.set-font-weight('black');
    line-height: 2.5rem;
    width: 85%;

    @include g.bp(lg) {
        padding-top: 40px;
    }
}

.moduleClose {
    align-self: center;
}

