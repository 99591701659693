@use '@/styles/global' as g;

.modalImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: g.$blue;
  padding: 24px 24px 0px;
  flex: 1 0 0;

  @include g.bp-max(md) {
    display: none;
  }

  .offerLegal, a {
      color: g.$white;
      flex: 1 0 0;
      width: 100%;
      @include g.font-size(12px);
      line-height: 1rem;
      text-decoration: none;
      display: flex;
      flex-direction: column;
  }
}
