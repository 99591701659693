@use 'src/styles/global' as g;

@keyframes footer-slide-up {
    0% {
        bottom: -200%;
    }
    100% {
        bottom: 0;
    }
}

@keyframes footer-slide-down {
    0% {
        bottom: 0;
    }
    100% {
        bottom: -200%;
    }
}
.gridContainer {
    background-color: #000000;
    @include g.bp-max(md) {
        padding: 0 !important;
    }
}

.carouselContainer {
    display: flex;
    &.initCarousel {
        .item:not(.active) {
            .footer {
                opacity: 0;
            }
        }
    }
    .item {
        width: 17%;
        min-width: 0;
        height: 500px;
        position: relative;
        transition: width 0.25s ease-out, min-width 0.25s ease-out;  
        overflow: hidden;
        &:hover {
            cursor: pointer;
        }
        &.active {
            position:relative;
            width: 600px;
            min-width: 600px;
            height: auto;
            
            &:hover {
                cursor: auto;
            }
            .bgImage {
                &::before {
                    opacity: 0;
                }
            }
            .footer {
                display: flex;
                animation: footer-slide-up 0.25s both;
                animation-delay: 0.2s;
            }

            @include g.bp-max(md) {
                min-width: unset;
            }   
        }
        .bgImage {
            height: 500px;
            display: flex;
            justify-content: center;
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
            &::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 1;
                transition: opacity 0.5s;
                background-color: rgba(0, 0, 0, 0.5);
            }
            img {
                object-fit: cover;
            }
        }
    }

    @include g.bp-max(md) {
        display:none;
    }
}

.footer {
    position: absolute;
    width: 100%;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 12px;
    padding-top: 0;
    overflow: hidden;
    display: flex;
    animation: footer-slide-down 0.25s both;
    gap: 8px;

    .linkContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    @include g.bp-max(md) {
        padding: 8px 16px 16px 16px;
        gap: 4px;
    }

    .footerText {
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        & > :first-child {
            display: flex;
            flex-direction: column;
            padding-top: 12px;
            gap: 8px;
        }
        .logoContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 12px;
        }
    }
    h3 {
        @include g.font-size(32px);
        line-height: 2rem;
        padding-top: 0;
    }
    p {
        @include g.font-size(16px);
        line-height: 1.4rem;
    }
    .logo {
        width: 80px;
        height: auto;
        border-radius: 15%;
    }
    .channelNumber {
        display: flex;
        width: 80px;
        justify-content: center;
    }
    .link {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        text-decoration: none;
        border: 1px solid #fff;
        padding: 4px 8px;
        @include g.font-size(16px);
        a {
            text-decoration: none;
            color: #fff;
            padding-right: 10px;
            width: max-content;
        }
        .arrow_image {
            display: flex;
        }
    }
}

.mobileCarousel{
    display: flex;
    &.initCarousel {
        .item:not(.active) {
            .footer {
                opacity: 0;
            }
        }
    }

    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;

    .mobileCarouselContainer {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }
    .item {
        overflow: visible;
        width: unset;
        min-width: unset;
        height: unset;
        scroll-snap-align: start;
        &.active {
            width: unset;
        }
        .bgImage {
            height: unset;
            width: 100vw;
            margin-bottom: 32px;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(transparent 67%, black);
            }

            img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                min-width: 343px;
                padding: 0 16px;
            }
        }
        .footer {
            position: absolute;
            display: flex;
            bottom: 0%;
            opacity: 1;
            animation: none;
            padding: 0px 16px 8px;
            gap: 8px; 
            width: 100vw;
            .logoContainer {
                padding-top: 12px;
            }
            .logo {
                width: 64px;
            }
            h3 {
                @include g.font-size(20px);;
                line-height: 1.25rem;
            }
            p {
                @include g.font-size(14px);
                line-height: 18px;
            }
            .link {
                @include g.font-size(14px);
                padding-bottom: 0.25rem;
            }
            .footerText {
                padding-bottom: unset;
                display: flex;
                & > :first-child {
                    gap: 4px;
                }
            }
            .channelNumber {
                width: 64px;
            }
        }
    }
    @include g.bp(md) {
        display: none;
    }
}

.mobileCarouselTabs {
    display: flex;
    padding: 16px 20px 10px;
    gap: 16px;

    .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        &.active > div.tabIndicator{
            background-color: #fff;
        }
    }

    .thumbnailImage {
        aspect-ratio: 6/5;
        img {
            object-fit: cover;
            height: 100%;
        }
    }

    .tabIndicator {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: transparent;
    }

    @include g.bp(md) {
        display: none;
    }
}