@use '@/styles/global' as g;

.item {
    display: flex;
    padding: 32px 0 12px;
    border-bottom: 1px solid g.$gray-light;

    @include g.bp(md) {
        padding-bottom: 32px;
    }
}

.imageContainer {
    min-width: 100px;
    max-width: 100px;
}

.image {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.name {
    display: flex;
    align-items: center;
    height: 16px;
    margin-bottom: 4px;
}

.showMore {
    padding: 0 12px 0 20px;
    width: 100%;

    button:last-of-type {
        margin: 24px 0;
        font-size: 13px;

        @include g.bp(md) {
            display: none;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;

    @include g.bp(md) {
        flex-direction: row;
    }
}

.infoLeft {
    flex: 1;

    p {
        height: 56px;
        overflow: hidden;

        @include g.bp(md) {
            height: auto;
            overflow: visible;
        }
    }
}

.showHideables .infoLeft p {
    height: auto;
    overflow: visible;
}

.infoRight {
    width: 100%;

    span:first-child {
        margin-top: 0;
    }

    @include g.bp(md) {
        width: 33%;
        padding-left: 12px;
        margin-left: 12px;
    }
}

.socialMedia {
    margin: 24px 0 12px;
}

.hideable {
    display: none;

    @include g.bp(md) {
        display: flex;
    }
}

.showHideables .hideable {
    display: flex;
}

.showMoreText button:last-of-type {
    margin-top: 24px;
    font-size: 13px;
}

.hostItem {
    margin-bottom: 12px;
}

.hideFinalSeperator.item:last-of-type {
    border-bottom: none;
    padding-bottom: 0;

    button {
        margin-bottom: 0;
    }
}

.groupDisplay:not(.groupHide) {
    display: none;
}