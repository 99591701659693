@use '@/styles/global' as g;
  
.toastIcon {
    width: 32px;
    height: 32px;

    @include g.bp-max(md) {
        align-self: center;
    }   
}
  
.toastContent {
    align-self: stretch;
    display: flex;
    width: auto;
    margin: auto 0;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 324px;
}

.toastBar {
    background-color: g.$blue;
    height: 12px;
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 8px 8px 0 0;
}
  
.toastHeading {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.toast {
    border-radius: 8px;
    background-color: g.$off-white;
    display: flex;
    flex-direction: column;
    max-width: unset;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    padding: 24px;

    h2 {
        @include g.font-size(16px);
        @include g.set-font-weight('bold'); 
        line-height: 1.5rem;
        color: g.$black;
    }

    p {
        @include g.font-size(14px);
        line-height: 1.5rem;
        color: g.$medium-black;
    }

    @include g.bp-max(md) {
       margin-bottom: 20px;
    }

    button {
        top: 32px;
        right: 24px;
    }
}
