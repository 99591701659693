@use '@/styles/global' as g;

.liveNowContainer {
    background: rgb(19, 31, 107);
    background: linear-gradient(90deg, rgba(19, 31, 107, 1) 0%, rgba(92, 29, 170, 1) 50%, rgba(35, 99, 160, 1) 100%);
    color: #fff;
    padding: 32px 0;
    @include g.bp(md){
        padding-bottom: 2.5rem;
        padding-top: 2rem;
    }
}

.heading {
    @include g.font-size(28px);
    margin-bottom: 1rem;
}

.liveNowInner {
    display: flex;
    flex-direction: column;

    .liveNowListOuter {
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none; 
    }
    .liveNowList {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 1024px;
        .liveNowItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 200px;
            padding-right: 0.5rem;
            &:last-child {
                padding-right: 0;
            }
            
            .channelLogo {
                height: 96px;
                width: 96px;
                align-content: center;
                margin-bottom: 0.75rem;
                &.roundImage {
                    img {
                        border-radius: 50%;
                    }
                }
                img {
                    width: 96px;
                    height: 96px;
                }
            }
            .roundBackground {
                border-radius: 50%;
                background-color: #D8CEFF;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 20px 20px;
                margin-bottom: 0.75rem;
                img {
                    width: 56px;
                    height: 56px;
                }
            }
            .showName {
                text-align: center;
                @include g.font-size(16px);
                @include g.set-font-weight('medium');
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                gap: 5px;
                .artist {
                    @include g.font-size(12px);
                    @include g.set-font-weight('book');
                }
                .title {
                    line-height: 20px;
                    display: flex;
                    min-height: 40px;
                    align-items: center;
                    justify-content: center;
                }
            }
            .channelLink {
                margin-top: 1rem;
                flex-grow: 1;
                align-content: flex-end;
                width: 100%;
                a {
                    display: flex;
                    width: 100%;
                    color: #fff;
                    text-decoration: none;
                    border: 1px solid #fff;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-bottom: 0.125rem;
                    font-weight: 500;
                    .rightTriangle {
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;
                        border-left: 10px solid #fff;
                        display: inline-block;
                        width: 0px;
                        height: 0px;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}
