@use '@/styles/global' as g;

.listenWrapper {
    height: 284px;

    @include g.bp(md) {
        height: 320px;
    }
}

.headlineContainer {
    .heading {
        margin-bottom: 8px;

        @include g.bp(md) {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            margin-bottom: 0;
        }
    }

    .center {
        text-align: center;
    }

    &.Hero {
        padding-top: 24px;
        padding-bottom: 24px;

        @include g.bp(md) {
            padding-top: 48px;
            padding-bottom: 48px;
        }
    }

    &.Legal {
        padding-top: 24px;
        padding-bottom: 24px;

        @include g.bp(md) {
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }

    &.Listen {
        height: 100%;
        position: relative;
        padding: 0px 16px;

        @include g.bp(md) {
            padding: 0px;
        }

        .contentWrapper {
            width: 100%;
            max-width: 330px;
            margin: 0 auto;

            @include g.bp(md) {
                width: 440px;
                max-width: 440px;

            }
        }

        .foregroundImageStyles {
            position: relative;
            width: auto;
            max-width: 330px;
            top: 47px;

            @include g.bp(md) {
                top: 36px;
                max-width: 440px;
            }
        }

        .pinToBottom {
            position: absolute;
            bottom: 13px;
            max-width: 230px;

            @include g.bp(md) {
                max-width: 396px;
            }
        }
    }

    &.withBreadcrumbs {
        @include g.bp(md) {
            padding-top: 24px;
            padding-bottom: 36px;
        }
    }
}