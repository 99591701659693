@use '@/styles/global' as g;

.showSchedule {
    display: block;
    width: 100%;
    padding: 0;
    @include g.font-size(12px);

    .scheduleTimeBlock {
        margin-bottom: 12px;
    }

    .scheduleLabel {
        width: 33%;
        max-width: 125px;
        @include g.set-font-weight('bold');
        color: g.$gray-dark;
        display: inline-block;
        white-space: nowrap;
        flex-shrink: 0;

        @include g.bp(md) {
            max-width: 105px;
        }
    }

    .scheduleTitle {
        margin-bottom: 4px;
    }

    .scheduleTimeRow {
        display: flex;
        flex-wrap: nowrap;
        border-top: 1px solid g.$gray-light;
        line-height: 2.5;

        .scheduleTimeGroup {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: auto;

            .scheduleSlot {
                margin-right: 4px;
                color: g.$gray-dark;

                &:last-child {
                    margin-right: 0;

                    &::after {
                        content: '';
                    }
                }

                &::after {
                    content: ',';
                }
            }
        }
    }
}