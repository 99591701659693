@use '@/styles/global' as g;

.clipContainer,
.pingPongClipContainer {
    display: grid;
    gap: 8px;

    @include g.bp(md) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}

.pingPongClipContainer {
    background-color: var(--white);

    @include g.bp(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

.item .itemContainerbutton {
    text-decoration: none;
    width: 100% !important;
    max-width: 100%;
}

.imageAbsolute,
.pingPongImageAbsolute {
    position: relative;

    .icon {
        position: absolute;
        left: 18px;
        bottom: -28px;
        height: 48px;
        width: 48px;

        circle {
            fill: var(--solitude-blue) !important;
        }

        path {
            fill: var(--blue) !important;
        }
    }
}

.imageAbsolute {
    padding-bottom: 56.25%;
    position: relative;

    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        transition:
            height 0.25s ease,
            width 0.25s ease;
    }
}

.clipContent {
    padding: 24px;
    position: relative;
    color: var(--black);
    text-align: left;

    .linkLike {
        @include g.link-underline-properties;
        color: var(--blue-secondary);
        display: inline-block;
        margin-bottom: 12px;
    }

    .arrow {
        visibility: hidden;
        display: inline-block;
        position: absolute;
        width: 15px;
        padding-top: 5px;
        padding-left: 5px;
        stroke: var(--black) !important;
    }

    .header {
        margin-bottom: 8px;
        @include g.font-size(16px);
        @include g.set-font-weight('black');
        line-height: 24px;
    }
}

.item:hover {
    z-index: 2;

    .icon {
        circle {
            fill: var(--blue) !important;
        }

        path {
            fill: var(--solitude-blue) !important;
        }
    }

    .arrow {
        visibility: visible;
    }

    .imageAbsolute img {
        width: calc(100% + 16px);
    }
}

.pingPongImageAbsolute {
    order: unset;

    .pingPongPlayButton {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);

        .pingPongPlayButtonIcon {
            width: 66px;

            circle {
                fill: var(--blue) !important;
            }

            path {
                fill: var(--solitude-blue) !important;
            }
        }
    }

    @include g.bp(md) {
        order: var(--imageWrapperOrder);

        .pingPongPlayButton {
            left: 100%;
            bottom: 5%;
            transform: translate(-50%);
        }

        .leftAlign {
            left: 0;
        }
    }

    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}

.clipContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    order: unset;

    .header {
        @include g.font-size(28px);
        font-weight: 800;
        line-height: 31px;
        margin-bottom: 8px;

        @include g.bp(md) {
            margin-bottom: 16px;
        }
    }

    .clipContent {
        padding: 40px 24px;

        .bodyText {
            span {
                margin-bottom: 0;
            }

            a {
                color: var(--blue-secondary);
            }
        }
    }

    @include g.bp(md) {
        order: var(--textWrapperOrder);

        .clipContent {
            max-width: 240px;
            padding: 0px;
        }
    }
}

.imageStyles {
    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}