@use '@/styles/global' as g;

.container {
    box-shadow: g.$active-shadow-heavy;

    ul {
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin: 0;
        list-style-type: none;
        background-color: g.$white;
    }

    a,
    button {
        font-size: 14px;

        &:not(.disabled) {
            color: g.$blue-secondary;
        }
    }
}

.details {
    padding: 24px 32px;
    background-color: g.$off-white;
    color: g.$gray-charcoal;
    font-size: 12px;

    p {
        font-size: inherit;
    }
}

.row {
    display: flex;
    align-items: center;
    padding: 16px;
    height: 56px;
    width: 100%;
    @include g.set-font-weight('bold');
    text-decoration: none;

    img,
    svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        fill: g.$blue-secondary;
    }

    .arrow {
        display: flex;
        align-items: center;

        svg {
            width: 26px;
            height: 26px;
            margin-left: 10px;
            transition: margin 0.1s ease-in-out;

            g {
                stroke: none;

                circle {
                    fill: none;
                }

                path {
                    stroke: g.$blue-secondary;
                }
            }
        }
    }

    &:hover {
        background-color: g.$blue-ultra-light;

        .arrow {
            svg {
                margin-left: 0;
            }
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        color: g.$gray-charcoal;

        .arrow {
            display: none;
        }

        &:hover {
            background-color: none;

            .arrow {
                svg {
                    margin-left: 10px;
                }
            }
        }
    }
}