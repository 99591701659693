@use '@/styles/global' as g;

.mainContainer {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-medium);
}

.textContainer {
    width: 75%;
}

.bottomlink a {
    text-decoration: none;
    color: g.$blue-secondary;

    &:hover {
        text-decoration: underline;
    }
}

.label {
    @include g.font-size(12px);
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
    color: var(--gray-dark);
}

.titleLink {
    text-decoration: none;
    color: var(--black);
    font-size: 20px;
    line-height: 24px;

    .title {
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        color: var(--blue-secondary);
    }

    &:last-child {
        .title {
            margin-bottom: 0;
        }
    }
}

.mainText {
    display: -webkit-box;
    max-height: 45px;
    margin-bottom: 4px;
    margin-top: 2px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 22px;
    color: var(--gray-dark);
}

.linkWithIconWrapper {
    margin-top: 24px;
}

.link {
    display: inline-flex;
    align-items: center;

    .arrowIcon {
        visibility: visible;
    }

    @include g.bp(md) {
        .arrowIcon {
            visibility: hidden;
        }

        &:hover,
        &:focus {
            .arrowIcon {
                visibility: visible;
            }
        }
    }
}

.linkText {
    display: inline;
    margin: 0;
}

.arrowIcon {
    visibility: hidden;
    width: 12px;
    margin-left: 3px;
    stroke: var(--blue-secondary);
}

.imageContainer {
    box-sizing: border-box;
    height: auto;
    width: 64px;
    position: relative;

    @include g.bp(sm) {
        width: 88px;
    }
}

.cardMainContainer {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 240px;
    padding: 20px;
    margin-bottom: 4px;

    &:last-child {
        margin-right: 0;
    }

    &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: g.$white;
        box-shadow: g.$active-shadow;
        transition:
            box-shadow 0.25s,
            scale 0.25s;
    }

    .textContainer {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .titleLink {
        margin-bottom: 4px;
    }

    .cardMainText {
        display: -webkit-box;
        margin: 4px 0px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        line-height: 18px;
        color: var(--gray-dark);
    }

    &:hover {
        box-shadow: g.$hover-shadow;
        transition:
            box-shadow 0.25s ease,
            height 0.25s ease,
            width 0.25s ease;
        z-index: 2;

        .titleLink {
            text-decoration: underline;
            color: var(--blue-secondary);
        }

        @include g.bp(md) {
            &::after {
                scale: 1.1;
            }
        }
    }
}

.paginationNavigationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @include g.bp(md) {
        margin-top: 56px;
    }
}

.articleWrapperStyles {
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.linkWrapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    @include g.bp(md) {
        margin-top: 56px;
    }

    .textLinkStyles {
        @include g.font-size(14px);
        line-height: 1.6;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        // Always try to avoid using !important, but in this case I don't see an alternative
        color: g.$secondary-atlas-web-blue !important;

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    }
}