@use 'src/styles/global/mixins' as m;
@use 'src/styles/global/color-palette' as c;

.recirculation {
    padding: 16px;
    text-align: center;
    width: 100%;
    border-width: 4px;
    border-style: solid;
    background-color: c.$white;

    @include m.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
    }

    @include m.bp(md) {
        padding: 28px 32px;
    }

    p {
        color: c.$gray-dark;
        margin-bottom: 8px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        margin: 0 auto;
    }

    &.darkTheme {
        background-color: #191919;
        border-radius: 24px;
        border-color: #606060;
        max-width: 598px;
        margin: 0 auto;

        p {
            color: var(--inverse-contrast);
        }
    }
}
