@use '@/styles/global' as g;

.header {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    button {
        margin-right: 1rem;
        padding-bottom: 0.5rem;
        @include g.font-size(16px);
        border-bottom: 4px solid transparent;
        border-right: none;
        border-left: none;
        &.activeTab {
            border-bottom: 4px solid g.$blue;
            border-right: none;
            border-left: none;
        }
    }
}

.subHeader {
    display: flex;
    flex-direction: row-reverse;
    color: g.$silver;
}

.showsContainer,
.hostsContainer {
    display: none;
    &.active {
        display: block;
    }
}

.scheduleItem {
    display: none;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    &.visible {
        display: flex;
    }
    .channelImage {
        margin-right: 1.5rem;
        align-content: center;
        height: 118px;
        img {
            width: 83.25px;
            height: auto;

            @include g.bp(md) {
                width: 108px;
            }
        }
    }
    .showContent {
        display: flex;
        flex-direction: column;
        .title {
            @include g.font-size(16px);
            @include g.set-font-weight('bold');
            margin-bottom: 0.75rem;
        }
        .nextPlay {
            margin-bottom: 0.75rem;
            color: g.$almost-black;
            .nowPlayingHeadline {
                color: g.$black;
            }
        }
        .description {
            @include g.font-size(16px);
            margin-bottom: 0.75rem;  
            &.mobileDescription {
                display: none;
            }
            &.desktopDescription {
                display: inline;
            }

            @include g.bp-max(md) {
                @include g.font-size(14px);
                &.mobileDescription {
                    display: inline;
                }
                &.desktopDescription {
                    display: none;
                }
            }
        }
        .scheduleButton {
            border: none;
            display: flex;
            color: g.$blue;
            flex-direction: row;
            align-items: center;
            &.active {
                svg {
                    transform: rotateX(180deg);
                }
            }
            svg {
                margin-left: 0.25rem;
                width: 10px;
                path {
                    stroke: g.$blue;
                    stroke-width: 2px;
                }
            }
        }
        .schedule {
            margin-top: 1rem;
            display: none;
            &.active {
                display: block;
            }
        }
    }
}

.hostItem {
    display: none;
    &.visible {
        display: block;
    }
}

.host {
    div[class*='host_infoLeft'] {
        p {
            > span {
                @include g.font-size(14px);
            }
        }
    }
    div[class*='host_hideable'] {
        display: none;
    }
}

.host_hideable {
    display: none;
}

.footer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    &.showMore {
        button {
            svg {
                transform: rotateX(180deg);
            }
        }
    }
    button {
        color: g.$gray-dark;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-left: 0.25rem;
            width: 10px;
            path {
                stroke: g.$gray-dark;
                stroke-width: 2px;
            }
        }
    }
}
