@use '@/styles/global' as g;

.contentContainer {
    width: 100%;
    position: relative;
    margin: 4px 0;
}

.backgroundWrapper {
    width: 100%;
    height: 100%;
}

.contentContainer:hover,
.contentContainer:focus-within {

    .colorBorder {
        @include g.web2PageOverrides {
            background-color: g.$white;
        }
    }
}

@include g.bp(lg) {

    .contentContainer:hover,
    .contentContainer:focus-within {
        z-index: 5;

        .colorBorder {
            right: -8px;
            top: -8px;
            bottom: -8px;
            width: 160px;
            height: 112px;
            z-index: 5;

            @include g.web2PageOverrides {
                border-radius: var(--rounding-large, 24px);
                background-color: g.$white;
            }

            .borderBackground {
                opacity: 0.15;
            }

            .listenLiveText {
                opacity: 1;
            }

            .playIcon {
                opacity: 1;
            }
        }

        .bannerContainer {
            .bannerBackground {
                width: calc(100% + 16px);
                left: -8px;
            }
        }

        .shadow {
            left: -8px;
            right: -8px;
            bottom: -8px;
            top: -8px;
            box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
        }

        &.hasBanner {
            .shadow {
                bottom: 0;
            }
        }
    }
}

.backgroundWrapper {
    height: 96px;
}

.contentWrapper {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    height: 100%;
    z-index: 3;
    right: 0;
    padding: 0 20px;

    @include g.bp-max(md) {
        transition: margin 0.2s;
    }
}

.radioInfoContainer {
    display: flex;
    width: 120px;
    margin-right: 4px;

    @include g.bp(sm) {
        flex-grow: 2;
    }
}

.radioLink {
    display: none;

    .titleLink {
        outline-offset: -1px;
        color: g.$black;
    }

    @include g.bp(md) {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 200px;
        flex-shrink: 0;

        a {
            @include g.font-size(14px);
            @include g.set-font-weight('bold');
            text-decoration: none;
            color: g.$black;
        }
    }
}

.radioImageContainer {
    @media (min-width: 0px) and (max-width: 374px) {
        display: none;
        outline-offset: -1px;
        align-self: center;
    }

    @media (min-width: 375px) {
        display: flex;
        align-items: center;
        width: 90px;
        justify-content: center;
    }

    @include g.bp(sm) {
        width: 234px;
    }
}

.radioImageLink {
    display: none;

    @include g.bp(xs) {
        max-width: 80px;
        height: 100%;
        padding: 8px 0px;
        display: flex;
        align-items: center;

        img {
            max-height: 100%;
            width: 80px;
        }
    }
}

.radioTextInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    min-width: 0;

    .title {
        @include g.font-size(14px);
        @include g.set-font-weight('bold');
        margin-bottom: 2px;
    }

    .subTitle {
        @include g.font-size(12px);
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1.15;
    }

    .explicitLanguage {
        display: flex;
        align-items: center;
        @include g.font-size(12px);
        margin-bottom: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1.15;

        p {
            margin-bottom: 0px;
            margin-right: 4px;
        }

        .explicitLanguageIndicator {
            @include g.set-font-weight('bold');
        }
    }

    .subTitleDetails {
        display: none;
        color: g.$gray-charcoal;
        margin: 2px 0 8px;
        @include g.font-size(12px);
        line-height: 1.15;
    }

    p,
    a {
        @include g.font-size(12px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .showAndScheduleLink {
        text-underline-offset: 1px;
        text-decoration-thickness: 1px;
    }

    @include g.bp(md) {
        .title {
            display: none;
        }

        .subTitleDetails {
            display: inline;
            margin: 0 0 8px 0;
        }
    }
}

.channel {
    z-index: 1;
    display: flex;
    align-items: center;
    color: g.$gray-charcoal;
    width: 56px;
    transition: width 0.2s;
    overflow: hidden;
    margin-left: 4px;
    @include g.set-font-weight('bold');
    justify-content: center;
    @include g.font-size(12px);

    svg {
        width: 15px;
        stroke: g.$gray-dark;
        fill: g.$gray-dark;
    }

    @include g.bp(lg) {
        margin-right: 16px;
    }
}

.albumContainer {
    display: flex;
    border: 2px;
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    text-decoration: none;

    .button {
        display: flex;
        background: none;
        border: none;
        height: 100%;
        align-items: center;
        padding-left: 4px;
        transition: padding 0.2s;
        z-index: 0;

        @include g.bp(lg) {
            display: none;
        }

        .kabab {
            width: 24px;
            margin-right: 8px;
            transition: margin 0.2s;
        }
    }

    .albumWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 88px;
        background-color: g.$gray-semi-dark;

        @media only screen and (min-width: 375px) {
            width: 104px;
            height: 104px;
        }
    }
}

.colorBorder {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    width: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition:
        right 0.2s,
        top 0.2s,
        width 0.2s,
        bottom 0.2s,
        height 0.2s;
    overflow: hidden;
    background-color: g.$white;

    @include g.web2PageOverrides {
        width: 10px;
        background-color: transparent;
        border-radius: var(--rounding-large, 24px);
    }

    .borderBackground {
        position: absolute;
        right: 0;
        top: 0;
        width: 120px;
        height: 100%;
        opacity: 1;
        z-index: 1;
        transition: opacity 0.2s;

        @include g.web2PageOverrides {
            border-radius: var(--rounding-large, 24px);
        }

        @include g.bp(lg) {
            width: 128px;
        }
    }

    .listenLiveText {
        display: flex;
        width: 70px;
        opacity: 0;
        position: relative;
        height: 40px;
        @include g.font-size(12px);
        margin-left: 30px;
        padding-left: 65px;
        @include g.set-font-weight('bold');
        color: g.$blue;
        letter-spacing: 1.7px;
        justify-content: center;
        overflow-wrap: break-word;
        text-align: center;
        transition: opacity 0.2s;
        text-transform: uppercase;
        height: 100%;
        align-items: center;

        @include g.bp(lg) {
            margin-left: 32px;
            padding-left: 72px;
        }
    }

    .playIcon {
        // icon is not centered in paint bounds
        // so numbers are compensating for that
        // to center and size icon to 48px
        position: absolute;
        top: calc(50% - 27px);
        left: calc(72px - 50%);
        width: 66px;
        opacity: 0;
        z-index: 2;
        transition:
            top 0.2s,
            left 0.2s,
            opacity 0.2s;

        @include g.bp(lg) {
            left: calc(79px - 50%);
        }
    }
}

.bannerContainer {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 34px;
    width: 100%;
    z-index: 5;

    .bannerBackground {
        position: absolute;
        width: 100%;
        height: 100%;
        background: g.$purple;
        z-index: 1;
        transition:
            width 0.2s,
            left 0.2s;
        left: 0px;

        @include g.web2PageOverrides {
            background: var(--base-surface-strong, #f0f0f0);
            border-radius: 0 0 var(--rounding-medium, 16px) var(--rounding-medium, 16px);
        }
    }

    .bannerText {
        color: g.$white;
        @include g.set-font-weight('book');
        @include g.font-size(12px);
        width: 100%;
        padding: 8px 20px;
        z-index: 2;

        @include g.web2PageOverrides {
            color: var(--base-contrast, #202020);
        }
    }
}

@include g.bp-max(lg) {
    .active {
        .channel {
            width: 0px;
        }

        .albumContainer {
            .button {
                padding-right: 130px;
            }
        }

        .colorBorder {
            width: 150px;

            .borderBackground {
                opacity: 0.15;
            }

            .listenLiveText {
                display: flex;
                opacity: 1;
            }

            .playIcon {
                opacity: 1;
            }
        }

        @include g.bp-max(md) {
            .shadow {
                margin-left: -80px;
            }

            .contentWrapper {
                margin-left: -80px;
            }
        }
    }
}

.shadow {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    left: 0;
    box-shadow: g.$active-shadow;
    background: white;
    transition: all 0.2s;

    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
    }
}