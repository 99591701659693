@use '@/styles/global' as g;

.modalOfferIcon {
    width: 130px;
    
    img {
        border-radius: 15px;
    }

}

.blueBackground {
    background-color: g.$blue;
    padding: 16px 0 0;
    width: 100%;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: center;

    @include g.bp(md) {
        display: none;
    }

    .offerLegal, a {
        width: 100%;
        color: g.$white;
        flex: 1 0 0;
        @include g.font-size(12px);
        line-height: 1rem;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 16px;
    }
}