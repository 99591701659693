@use '@/styles/global' as g;

.modalAppIcon {
    width: 100px;
    padding-top: 24px;
    
    img {
        border-radius: 15px;
    }

    @include g.bp(md) {
        display: none;
    }
}