@use '@/styles/global' as g;

$SiriusXM-Light-Grey-Background: #F7F7F7;
$SiriusXM-Web-Text-Grey: #646464;

.buttonContainer {
    display: flex;
    @include g.font-size(16px);
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;

    button {
        cursor: pointer;
        @include g.font-size(16px);
        @include g.set-font-weight('medium');
        line-height: 1.5rem;
        padding: 1rem 1.5rem;
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
        white-space: normal;
        border: none;
        border-radius: 40px;
        background: g.$blue;
        color: g.$white;
        display: flex;
        align-items: center;

        @include g.bp-max(md) {
            padding: 1rem 1.5rem;
        }

        @include g.bp(lg) {
            padding: 1rem 1.5rem;
        }   
    }

    .buttonArrow {
        display: flex;
        height: 16px;
        width: 16px;
        align-self: center;
        justify-content: center;
        margin-left: 16px
    }

    .buttonText {
        width: 100%;
    }
}

.screenContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include g.font-size(16px);
    row-gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    & > div {
        display: flex;
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: auto;
    
        label {
            font-size: 1rem;
            padding-top: 16px;
            padding-bottom: 8px;
            padding-left: 4px;
            align-self: center;
        }
        
        input {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5rem;
            padding: 1rem 1.5rem;
            margin: 0;
            border: 1px solid #ccc;
            background: g.$white;
            color: g.$black;
            box-shadow: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    
        input:focus {
            outline: 0;
        }
    }
    
    input {
        @include g.font-size(16px);
        line-height: 1.5rem;
        text-align: center;
        padding: 1rem 1.5rem;
        border: 0px solid g.$white;
        border-radius: 30px;
        background: g.$white;
        color: $SiriusXM-Web-Text-Grey;
    }
    
    button {
        cursor: pointer;
        @include g.font-size(16px);
        @include g.set-font-weight('medium');
        line-height: 1.5rem;
        padding: 1rem 1.5rem;
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
        width: 75%;       
        white-space: normal;
        border: none;
        border-radius: 40px;
        background: g.$blue;
        color: g.$white;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include g.bp-max(md) {
            padding: 1rem 1.5rem;
        }

        @include g.bp(lg) {
            padding: 1rem 1.5rem;
            width: 75%;
        }   
    }

    .buttonArrow {
        display: flex;
        height: 16px;
        width: 16px;
        align-self: center;
        justify-content: center;

        @include g.bp-max(md) {
            display: none;
        }
    }

    .buttonText {
        width: 100%;
    }
}
