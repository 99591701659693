@use '@/styles/global' as g;

.showLineup {
    max-width: 1024px;
    padding: 32px 0;
    border-bottom: 1px solid g.$gray-light;
    display: flex;
    flex-wrap: wrap;

    .lineupImg {
        width: 25%;
        max-width: 102px;

        img {
            max-width: 102px;
            max-height: 102px;
        }
        .roundCorners {
            @include g.web2PageOverrides {
                border-radius: var(--rounding-medium, 16px);
            }
        }
    }

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .lineupDetails {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        padding: 0 12px;

        .lineupName {
            max-width: 1024px;
            margin-top: 0;
            margin-bottom: 4px;
            padding: 0 12px;
            font-weight: bolder;
            flex: 100%;
        }
    }

    .lineupContent {
        width: 100%;

        .contentContainer {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            padding: 0;
        }

        .contentNextAir {
            width: 100%;
            align-items: flex-start;
            padding: 0 12px;
            display: flex;
            margin-bottom: 10px;

            @include g.bp(md) {
                display: flex;
                width: 33.33333%;
                margin-bottom: 0px;
            }

            .nextAirBlock {
                color: g.$gray-dark;
                @include g.font-size(12px);
                line-height: 1.5;

                .nowPlayingHeadline {
                    display: flex;
                    align-items: flex-end;
                    color: g.$black;
                    margin-right: 10px;
                }
            }
        }

        .contentDetails {
            height: auto;
            position: relative;
            overflow: hidden;
            padding: 0 12px;
            width: 100%;

            @include g.bp(md) {
                width: 66.66666%;
            }

            .shortDescription {
                display: block;
                margin-bottom: 0px;
                @include g.font-size(12px);
                line-height: 1.5;

                @include g.bp(md) {
                    display: none;
                }
            }

            .longDescription {
                display: none;
                @include g.font-size(12px);
                line-height: 1.5;

                @include g.bp(md) {
                    display: block;
                }

                a {
                    text-underline-offset: auto;
                    text-decoration-thickness: auto;
                }
            }

            .moreDescription {
                color: g.$gray-dark;
            }

            &.active {
                .shortDescription {
                    display: none;
                }

                .longDescription {
                    display: block;
                }
            }

            .showScheduleBtn {
                margin-top: 24px;
                font-weight: 700;
                display: block;
                text-align: left;
                margin-left: 0px;

                svg {
                    padding-top: 3px;
                    transform-origin: center center;
                    height: 10px;
                    width: 10px;
                    margin-left: 4px;
                    padding-top: 2px;

                    path {
                        stroke: g.$blue-secondary;
                        stroke-width: 2px;
                        @include g.web2PageOverrides {
                            stroke: var(--base-contrast-accent, #0000eb);
                        }
                    }
                }

                span {
                    color: g.$blue-secondary;
                    @include g.font-size(14px);
                    @include g.web2PageOverrides {
                        color: var(--base-contrast-accent, #0000eb);
                    }
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .showSchedule {
        display: block;
        width: 100%;
        padding: 0;

        .scheduleDropdown {
            padding: 0px;
            @include g.font-size(12px);
            line-height: 1.5;

            @include g.bp(md) {
                padding: 0 0 0 114px;
            }

            .scheduleDropdownDetail {
                display: none;
                margin-top: 15px;
                padding: 0px;

                @include g.bp(md) {
                    padding: 0 12px;
                }
            }

            .scheduleDropdownDetailExpanded {
                margin-top: 15px;
                padding: 0px;

                @include g.bp(md) {
                    padding: 0 12px;
                }

                display: flex;
                padding-right: 0px;
            }
        }

        &.active {
            .scheduleDropdownDetail {
                display: flex;
                padding-right: 0px;
                width: 100%;
            }
        }
    }
}

.showMoreText button:last-of-type {
    margin-top: 16px;
    @include g.font-size(13px);
}

.groupDisplay:not(.groupHide) {
    display: none;
}
