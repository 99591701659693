@use '@/styles/global' as g;

.modalHeader{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px; 
    padding-left: 16px;
    padding-right: 16px;

    .heading {
        @include g.font-size(32px);
        @include g.set-font-weight('black');
        color: g.$black;
        line-height: 2rem;

        @include g.bp(lg) {
            @include g.font-size(48px);
            line-height: 3rem;
        }
    }
    .subheading {
        @include g.font-size(16px);
        color: g.$medium-black;

        @include g.bp(lg) {
            @include g.font-size(18px);
        }
    }

    @include g.bp(lg) {
        padding-top: 32px; 
        padding-left: 16px;
        padding-right: 16px;
    }
}