@use '@/styles/global' as g;

$SiriusXM-Disabled-Button-Background: rgba(176, 176, 176, 1);
  
.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 16px;

    @include g.bp(lg) {
      margin-bottom: 32px;
      padding-right: 32px;
    }

  label {
      font-size: 1rem;
      padding-top: 16px;
      padding-bottom: 8px;
      padding-left: 4px;
      align-self: flex-start;

      @include g.bp-max(md) {
          padding: 0;
      }

      @include g.bp(lg) {
          padding-top: 16;
          padding-bottom: 8px;
      }
    }
}

.buttonWrapper {
  padding: auto;
  width: 100%;
  padding: 0 16px;

  @include g.bp(lg) {
    margin-left: unset;
    padding: 0 16px;
  }

  &.disabled {
    background-color: $SiriusXM-Disabled-Button-Background;
    cursor: not-allowed;
  }
}

div.modalClose,
a.modalClose {
  align-self: center;
  @include g.font-size(14px);
  color: g.$black;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 12px 24px;
  margin: 8px 0;

  @include g.bp-max(md) {
    padding: 12px 16px;
  } 

  @include g.bp(lg) {
    padding: 12px 24px;
  }
}

.buttonArrow {
  display: flex;
  height: 12px;
  width: 12px;
  align-self: center;
  justify-content: center;
}

.subscribe {
  align-self: center;
  text-align: center;
  @include g.font-size(12px);
  margin: auto;
  width: 85%;
  padding-bottom: 8px;

  @include g.bp(md) {
    padding-bottom: 16px;
  }

  a {
      color: g.$blue;  
  }
}

.validationContainer {
  display: flex; 
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  font-family:
    "Helvetica Now Text",
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 16px;
  justify-content: center;
}

.errorSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 8px;

  @include g.bp(lg) {
    max-width: 100%;
  } 
}

.errorHeader {
  display: flex;
  width: 100%;
  align-items: stretch;
  gap: 8px;
  color: #202020;
  font-weight: 700;
  justify-content: flex-start;

  @include g.bp(lg) {
    max-width: 100%;
  } 
}

.errorIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  margin: auto 0;
  flex-shrink: 0;
}

.errorTitle {
  font-size: 14px;
  margin: 0;

  @include g.bp(lg) {
    max-width: 100%;
  } 
}

.errorMessage {
  color: #d83024;
  font-weight: 400;
  margin: 0;

  @include g.bp(lg) {
    max-width: 100%;
  } 
}

.errorBorder {
  border: 1px solid #d83024 !important;
}
